<div class="app-main-container">
	<mat-toolbar color="primary">
		<button mat-icon-button (click)="sidenav.opened ? this.dataExchange.opened.next(false): this.dataExchange.opened.next(true)" aria-label="Example icon-button with menu icon">
			<mat-icon> {{sidenav.opened ? 'close' : 'menu'}}</mat-icon>
			<!--			<mat-icon *ngIf="dataExchange.opened | async === true">menu</mat-icon>-->
			<!--			<mat-icon *ngIf="dataExchange.opened | async === false">close</mat-icon>-->
		</button>
		<span class="font-logo" routerLink="/" style="cursor: pointer">CapChaos</span>
		<span class="toolbar-spacer"></span>
		<!--		<button fxHide.lt-sm mat-icon-button class="example-icon favorite-icon" aria-label="Example icon-button with heart icon">-->
		<!--			<mat-icon>favorite</mat-icon>-->
		<!--		</button>-->
		<!--		<button fxHide.lt-sm mat-icon-button aria-label="LinkedIn reference">-->
		<!--		<button mat-icon-button aria-label="LinkedIn reference">-->
		<!--			<mat-icon>facebook</mat-icon>-->
		<!--		</button>-->
		<!--		<button (click)="linkedIn()"  mat-icon-button  aria-label="LinkedIn reference">-->
		<!--			-->
		<!--		</button>-->
		<div (click)="linkedIn()" class="linkedin-icon"></div>

	</mat-toolbar>

	<mat-sidenav-container fxFlex fxFlexFill>
		<mat-sidenav #sidenav [mode]='dataExchange.mode|async'
		             [opened]='dataExchange.opened | async'>
			<div fxLayout="column" fxFlex>
				<mat-nav-list>
					<mat-list-item routerLink="/" (click)="closeMobileSidebar()">
						<mat-icon matListIcon>business</mat-icon>
						<a routerLink="/" (click)="closeMobileSidebar()" matLine>Home</a>
					</mat-list-item>

					<mat-divider></mat-divider>
					<div mat-subheader>Services</div>

					<mat-list-item routerLink="services/business-intelligence" (click)="closeMobileSidebar()">
						<mat-icon matListIcon>fingerprint</mat-icon>
						<a routerLinkActive="active-link" routerLink="services/business-intelligence"
						   (click)="closeMobileSidebar()" matLine>Business Intelligence</a>
					</mat-list-item>

					<mat-list-item routerLink="services/software-strategy" (click)="closeMobileSidebar()">
						<mat-icon matListIcon>phonelink</mat-icon>
						<a routerLinkActive="active-link" routerLink="services/software-strategy"
						   (click)="closeMobileSidebar()" matLine>Software Strategy</a>
					</mat-list-item>

					<mat-list-item routerLink="services/tactical-management" (click)="closeMobileSidebar()">
						<mat-icon matListIcon>manage_accounts</mat-icon>
						<a routerLink="services/tactical-management" (click)="closeMobileSidebar()"
						   routerLinkActive="active-link" matLine>Tactical Management</a>
					</mat-list-item>
				</mat-nav-list>

				<span class="navigation-spacer"></span>

				<mat-nav-list>
					<mat-divider></mat-divider>
					<div mat-subheader>Legal</div>

					<mat-list-item routerLink="legal/terms" (click)="closeMobileSidebar()">
						<mat-icon matListIcon>policy</mat-icon>
						<a routerLinkActive="active-link" routerLink="legal/terms"
						   (click)="closeMobileSidebar()" matLine>Terms</a>
					</mat-list-item>

					<mat-list-item routerLink="legal/notice" (click)="closeMobileSidebar()">
						<mat-icon matListIcon>gavel</mat-icon>
						<a routerLink="legal/notice" (click)="closeMobileSidebar()"
						   routerLinkActive="active-link" matLine>Notice</a>
					</mat-list-item>
				</mat-nav-list>

			</div>
		</mat-sidenav>

		<mat-sidenav-content>
			<div #mainContent>
				<router-outlet (activate)="onActivate($event)"></router-outlet>
			</div>
		</mat-sidenav-content>
	</mat-sidenav-container>
</div>

