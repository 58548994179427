import { NgModule }                   from '@angular/core';
import { BrowserModule, Meta, Title } from '@angular/platform-browser';
import { AppRoutingModule }           from './app-routing.module';
import { AppComponent }               from './app.component';
import { ServiceWorkerModule }        from '@angular/service-worker';
import { environment }                from '../environments/environment';
import { BrowserAnimationsModule }    from '@angular/platform-browser/animations';
import { SharedModule }               from './shared/shared.module';
import { ComponentDataService }       from './_services/component-data.service';
import { LoggerService }              from './_services/logger.service';

@NgModule({
              declarations : [
                  AppComponent
              ],
              imports      : [
                  BrowserModule,
                  AppRoutingModule,
                  ServiceWorkerModule.register('ngsw-worker.js', {
                      enabled : environment.production,
                      // Register the ServiceWorker as soon as the application is stable
                      // or after 30 seconds (whichever comes first).
                      registrationStrategy : 'registerWhenStable:30000'
                  }),
                  BrowserAnimationsModule,
                  SharedModule
              ],
              providers    : [ ComponentDataService, LoggerService, Meta, Title ],
              bootstrap    : [ AppComponent ]
          })
export class AppModule {}
