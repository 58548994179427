import { ApplicationRef, Component, ElementRef, ViewChild } from '@angular/core';
import { SwUpdate }                                         from '@angular/service-worker';
import { ComponentDataService }                             from './_services/component-data.service';
import { Router }                                           from '@angular/router';
import { MatSnackBar }                                      from '@angular/material/snack-bar';
import { LoggerService }                                    from './_services/logger.service';

@Component({
               selector    : 'app-root',
               templateUrl : './app.component.html',
               styleUrls   : [ './app.component.scss' ]
           })
export class AppComponent {
    // public sidenavMode: MatDrawerMode = 'over'; //over, side, push
    @ViewChild('mainContent') private mainContentDiv!: ElementRef<HTMLElement>;
    public sidenavOpened: boolean = true;
    
    constructor(
        private appRef: ApplicationRef,
        public dataExchange: ComponentDataService,
        private log: LoggerService,
        private updates: SwUpdate,
        private readonly router: Router,
        public snackBar: MatSnackBar
        // private router: Router
    ) {
    
        let updateReady = updates.checkForUpdate();
        updateReady
            .then((res) => {
                      if (res) {
                          console.log('updates Coming')
                          let updateIt = updates.activateUpdate();
                          updateIt.then((res) => {
                              console.log('updates loaded');
                        
                              this.snackBar.open('Site updates available, reload in progress!', '', {
                                  duration   : 3000,
                                  panelClass : [ 'green-snackbar' ]
                              });
                        
                              setTimeout(() => {
                                  document.location.reload();
                              }, 3000);
                        
                          }, (rej) => {
                              console.log('updates loading failed');
                          });
                          // updateChecker.unsubscribe();
                      }
                  }
            )
        
        // let updateChecker = appRef.isStable.subscribe((stable) => {
        //     if (stable) {
        //         console.log('we are stable')
        //         let updateReady = updates.checkForUpdate();
        //         updateReady.then((res) => {
        //             if (res) {
        //                 console.log('updates Coming')
        //                 let updateIt = updates.activateUpdate();
        //                 updateChecker.unsubscribe();
        //             }
        //         })
        //     }
        // });
        
        // updates.available.subscribe(event => {
        //     console.log(event);
        //     // this.snackBar.open('App Updates available, reload in progress!', '', {
        //     //     duration   : 3000,
        //     //     panelClass : [ 'green-snackbar' ]
        //     // });
        //
        //     setTimeout(() => {
        //         updates.activateUpdate().then(() => document.location.reload());
        //     }, 3000);
        // });
    }
    
    onActivate(_event: any): void {
        if (this.mainContentDiv) {
            ( this.mainContentDiv.nativeElement as HTMLElement ).scrollIntoView();
        }
    }
    
    public linkedIn(): void {
        window.open('https://www.linkedin.com/in/franzgerhart/', '_blank');
    }
    
    public closeMobileSidebar(): void {
        !!this.dataExchange.deviceService.isMobile() && this.dataExchange.opened.next(false);
    }
}
