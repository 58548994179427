import { Injectable }            from '@angular/core';
import { BehaviorSubject }       from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatDrawerMode }         from '@angular/material/sidenav';
import { Meta, Title }           from '@angular/platform-browser';

@Injectable({providedIn : 'root'})
export class ComponentDataService {
    side: MatDrawerMode = 'side';
    over: MatDrawerMode = 'over';
    opened: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    mode: BehaviorSubject<MatDrawerMode> = new BehaviorSubject<MatDrawerMode>(this.side);
    
    constructor(
        public deviceService: DeviceDetectorService,
        private metaService: Meta,
        private titleService: Title
    ) {
        if (this.deviceService.isMobile()) {
            this.opened.next(false);
            this.mode.next(this.over);
        }
    }
    
    setBowserTags(title: string, description: string, imageURL: string): void {
        this.titleService.setTitle(title);
        this.metaService.updateTag({property : 'og:title', content : title});
        this.metaService.updateTag({name : 'description', content : description});
        this.metaService.updateTag({name : 'og:description', content : description});
        this.metaService.updateTag({property : 'og:image', content : imageURL});
    }
}
