import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path         : '',
        loadChildren : () => import('./home/home.module').then(m => m.HomeModule),
        data         : {preload : true}
    },
    {
        path         : 'services',
        loadChildren : () => import('./services/services.module').then(m => m.ServicesModule),
        data         : {preload : true}
    },
    {
        path         : 'legal',
        loadChildren : () => import('./legal/legal.module').then(m => m.LegalModule),
        data         : {preload : true}
    },
    {
        path       : '**',
        redirectTo : 'authentication/404',
        pathMatch  : 'full'
    }
];

@NgModule({
              imports : [ RouterModule.forRoot(routes) ],
              exports : [ RouterModule ]
          })
export class AppRoutingModule {}
